(function () {
  // メディアクエリ設定
  const mediaQuery = {
    sp: "(max-width: 599px)",
    tb: "(min-width: 600px) and (max-width: 959px)",
    pc: "(min-width: 960px) and (max-width: 1279px)",
    full: "(min-width: 1280px)"
  };
  const mq = (mqString) => {
    return window.matchMedia(mediaQuery[mqString]).matches;
  };

  // --------------------------------------
  // DOM操作系
  // --------------------------------------

  // headerの一番後ろの子要素を1つ追加
  // header menu を fixedにしたとき、この要素で高さをとる
  document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.header');
    const spacerDiv = document.createElement('div');
    header.appendChild(spacerDiv);
  });

  // カレンダー上部にタイトル要素を追加
  document.addEventListener('DOMContentLoaded', () => {
    const calendarWrap = document.querySelector('.aside .__block--calendar');
    if (calendarWrap) {
      // タイトル
      const addElem = document.createElement('div');
      addElem.classList.add('side___title');
      const span = document.createElement('span');
      span.textContent = "CALENDAR";
      addElem.appendChild(span);

      // calendarWrap.prepend(addElem);
      calendarWrap.insertBefore(addElem, calendarWrap.firstElementChild);
    }
  });

  // ハンバーガーメニュー操作
  // --------------------------------------
  // sp
  // --------------------------------------
  if (mq('tb') || mq('sp')) {
    const spHumWrap = document.querySelector('.gnav-sp .__menu');
    const spHumTrigger = spHumWrap.querySelectorAll('.__item--has-children > a');
    const spHumTriggerNode = Array.prototype.slice.call(spHumTrigger, 0);
    spHumTriggerNode.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const toggleMenu = trigger.nextElementSibling.nextElementSibling;

        if (trigger.classList.contains('toggle___open')) {
          // 閉じる
          toggleMenu.style.height = toggleMenu.getBoundingClientRect().height + 'px';
          setTimeout(() => {
            toggleMenu.style.height = '0px';
          }, 50);
          trigger.classList.remove('toggle___open');
        } else {
          // 開く
          trigger.classList.add('toggle___open');
          toggleMenu.style.height = 'auto';
          const autoHeight = toggleMenu.getBoundingClientRect().height;
          toggleMenu.style.height = '0px';

          if (!trigger.closest('ul').classList.contains('__tree')) {
            //console.log('2階層目以降です');
            // if(trigger.closest('ul').style.height !== 'auto') {
            //   trigger.closest('ul').setAttribute('data-height', trigger.closest('ul').style.height);
            // }
            trigger.closest('ul').style.height = 'auto';
          }
          setTimeout(() => {
            toggleMenu.style.height = autoHeight + 'px';
          }, 50);
        }
      });
    });
  }

  // headerメニュー表示切り替え
  // --------------------------------------
  // pc
  // --------------------------------------
  if (mq('full') || mq('pc')) {
    const pcCategory = document.getElementById('gnav_category');
    pcCategory.addEventListener('mouseenter', (event) => {
      pcCategory.querySelector('.header___nav_menu').classList.add('is___viewed');
    });

    pcCategory.addEventListener('mouseleave', (event) => {
      pcCategory.querySelector('.header___nav_menu').classList.remove('is___viewed');
    });

    // global nav 追従
    const pcGlobalNav = document.querySelector('.gnav-pc');
    const headerLead = document.querySelector('.header___lead');
    const headerUpper = document.querySelector('.header___upper');

    const pcGlobalNavHeight = pcGlobalNav.getBoundingClientRect().height;

    // Function
    const scrollFunc = () => {
      let leadHeight = 0;
      let upperHeight = 0;
      if (headerLead) {
        leadHeight = headerLead.getBoundingClientRect().height;
      }
      if (headerUpper) {
        upperHeight = headerUpper.getBoundingClientRect().height - 10;
      }
      // fixed追加・削除処理
      if (window.pageYOffset >= (leadHeight + upperHeight)) {
        pcGlobalNav.classList.add('fixed');
        pcGlobalNav.nextElementSibling.nextElementSibling.style.paddingTop = pcGlobalNavHeight + 'px';
      } else if (window.pageYOffset <= (leadHeight + upperHeight)) {
        pcGlobalNav.classList.remove('fixed');
        pcGlobalNav.nextElementSibling.nextElementSibling.style.paddingTop = 0;
      }
    }
    // Listener
    window.addEventListener('scroll', scrollFunc);
    document.addEventListener('DOMContentLoaded', scrollFunc);
  }

  // --------------------------------------
  // tablet
  // SP
  // --------------------------------------
  if (mq('tb') || mq('sp')) {
    // global nav 追従
    const spGlobalNav = document.getElementById('header___under_sp');
    const spGlobalNavHeight = spGlobalNav.getBoundingClientRect().height;
    const spGlobalnavPos = spGlobalNav.getBoundingClientRect().top + window.pageYOffset;
    const spMenu = document.getElementById('nav____sp');
    // Function
    const scrollFunc = () => {
      const scroll = window.pageYOffset;
      if (scroll >= spGlobalnavPos) {
        spGlobalNav.classList.add('fixed');
        spMenu.classList.add('is___viewed');
        document.querySelector('.header').nextElementSibling.style.paddingTop = spGlobalNavHeight + 'px';
      } else if (scroll <= spGlobalnavPos) {
        spGlobalNav.classList.remove('fixed');
        spMenu.classList.remove('is___viewed');
        document.querySelector('.header').nextElementSibling.style.paddingTop = 0;
      }
    }
    // Listener
    window.addEventListener('scroll', scrollFunc);
    document.addEventListener('DOMContentLoaded', scrollFunc);
  }

  // footerショッピングガイド開閉
  // --------------------------------------
  // sp
  // --------------------------------------
  if (mq('sp')) {
    const toggleTargs = document.querySelectorAll('.footer___shopping_guide_ttl');
    const toggleTargsTnode = Array.prototype.slice.call(toggleTargs, 0);
    toggleTargsTnode.forEach((elem) => {
      elem.addEventListener('click', (event) => {
        if (elem.classList.contains('is___open')) {
          elem.classList.remove('is___open');
          elem.parentElement.style.height = '';
        } else {
          elem.classList.add('is___open');
          elem.parentElement.style.height = 'auto';
          const autoHeight = elem.parentElement.getBoundingClientRect().height;
          elem.parentElement.style.height = '';
          setTimeout(() => {
            elem.parentElement.style.height = autoHeight + 'px';
          }, 50)
        }
      });
    });
  }

  // asideの検索窓にplaceholderを設定
  if (document.querySelector('.aside .__block--search input[type="text"]')) {
    document.querySelector('.aside .__block--search input[type="text"]').placeholder = "何かお探しですか？";
  }

  // asideのcategoryに固有id付与
  // --------------------------------------
  // sp
  // --------------------------------------
  if (mq('tb') || mq('sp')) {
    const asideCategory = document.querySelector('.aside .__block.__block--category');
    if (asideCategory) {
      asideCategory.setAttribute('id', 'footer___category');
    }
  }

  // スムーズスクロール
  // イージング関数
  const Ease = {
    easeInOut: function (t) {
      let c = 0;
      if (t < .5) {
        c = 4 * t * t * t;
      } else {
        c = (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      }
      return c;
    }
  }

  // アニメーションの Duration の設定
  var duration = 500;
  window.addEventListener('DOMContentLoaded', function () {
    // スムーススクロールのトリガーを取得
    const smoothScrollTriggers = document.querySelectorAll('a[href^="#"]');
    const sTnode = Array.prototype.slice.call(smoothScrollTriggers, 0);
    sTnode.forEach(function (smoothScrollTrigger, index) {
      // トリガーをクリックした時に実行
      smoothScrollTrigger.addEventListener('click', function (e) {
        // headerの高さ取得
        const headerHeight = document.querySelector('.header .__block--search').getBoundingClientRect().height + document.getElementById('nav____sp').getBoundingClientRect().height;

        // href属性の値を取得
        const href = smoothScrollTrigger.getAttribute('href');
        // 現在のスクロール位置を取得
        const currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
        // スクロール先の要素を取得
        const targetElement = document.getElementById(href.replace('#', ''));

        // スクロール先の要素が存在する場合はスムーススクロールを実行
        if (targetElement) {
          // デフォルトのイベントアクションをキャンセル
          e.preventDefault();
          e.stopPropagation();

          // スクロール先の要素の位置を取得
          const targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight; // headerと余白の分だけずらす
          // スタート時点の時間を取得
          const startTime = performance.now();
          // アニメーションのループを定義
          const loop = function (nowTime) {
            // スタートからの経過時間を取得
            const time = nowTime - startTime;
            // duration を1とした場合の経過時間を計算
            const normalizedTime = time / duration;
            // duration に経過時間が達していない場合はアニメーションを実行
            if (normalizedTime < 1) {
              // 経過時間とイージングに応じてスクロール位置を変更
              window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)));
              // アニメーションを継続
              requestAnimationFrame(loop);
              // duration に経過時間が達したら、アニメーションを終了
            } else {
              window.scrollTo(0, targetPosition);
            }
          }
          // アニメーションをスタート
          requestAnimationFrame(loop);
        }
      });
    });
  });
}());



// コラムページ
(function () {
  const toHome = document.querySelector('body.body-page-detail .wrapper.wrapper--page .__inner .__to-home');
  const mainDiv = document.querySelector('body.body-page-detail .wrapper.wrapper--page .__inner .main--page-detail');
  const contentSection = document.querySelector('body.body-page-detail .wrapper .__inner .main--page-detail .__content');
  if (mainDiv) {
    var mainNextDiv = document.querySelector('body.body-page-detail .wrapper .__inner .main').nextElementSibling;
  }

  // div.mainの次にdivがあれば移動
  if (mainNextDiv) {
    if (mainNextDiv.tagName == "DIV") {
      mainNextDiv.setAttribute('class', 'content___bottom');
      contentSection.appendChild(mainNextDiv);
    }
  }

  // TOPへ戻るボタンのDOM移動
  if (toHome) {
    if (mainDiv) {
      mainDiv.appendChild(toHome);
    }
  }
})();



// マイページ
(function () {
  // メディアクエリ設定
  const mediaQuery = {
    sp: "(max-width: 599px)",
    tb: "(min-width: 600px) and (max-width: 959px)",
    pc: "(min-width: 960px) and (max-width: 1279px)",
    full: "(min-width: 1280px)"
  };
  const mq = (mqString) => {
    return window.matchMedia(mediaQuery[mqString]).matches;
  };

  // メニュー開閉
  // --------------------------------------
  // sp
  // --------------------------------------
  if (mq('sp')) {
    const toggleTargs = document.querySelector('.body-mypage .main--mypage .p-mypage-navi .__toggle');
    const toggleMenu = document.querySelector('.body-mypage .main--mypage .p-mypage-navi .__body');
    if (toggleTargs) {
      if (toggleMenu) {
        toggleTargs.addEventListener('click', (event) => {
          if (toggleTargs.classList.contains('is___open')) {
            toggleTargs.classList.remove('is___open');
            toggleMenu.style.height = '';
          } else {
            toggleTargs.classList.add('is___open');
            toggleMenu.style.height = 'auto';
            const autoHeight = toggleMenu.getBoundingClientRect().height;
            toggleMenu.style.height = '';
            setTimeout(() => {
              toggleMenu.style.height = autoHeight + 'px';
            }, 50)
          }
        });
      }
    }
  }

  // マイページリストメニュー
  const bookmarkMenu = document.querySelector('.body-mypage-bookmark .main--mypage-bookmarklist-index .p-product-set');
  const bookmarkMenuButton = document.querySelector('.body-mypage-bookmark .main--mypage-bookmarklist-index .c-form .__toggle');
  const orderListMenu = document.querySelector('.body-mypage-order-list .main--mypage-order-list .p-mypage-search .__body ');
  const orderListButton = document.querySelector('.body-mypage-order-list .main--mypage-order-list .p-mypage-search .__toggle');

  mypageToggleMenu(bookmarkMenu, bookmarkMenuButton);   // mypage-bookmark
  mypageToggleMenu(orderListMenu, orderListButton);   // mypage-order_list


  function mypageToggleMenu(menuElem, buttonElem) {
    if (menuElem) {
      if (buttonElem) {
        buttonElem.addEventListener('click', (event) => {
          if (buttonElem.classList.contains('is___open')) {
            buttonElem.classList.remove('is___open');
            menuElem.style.height = '';
          } else {
            buttonElem.classList.add('is___open');
            menuElem.style.height = 'auto';
            const autoHeight = menuElem.getBoundingClientRect().height;
            menuElem.style.height = '';
            setTimeout(() => {
              menuElem.style.height = autoHeight + 'px';
            }, 50)
          }
        });
      }
    }
  }
})();

